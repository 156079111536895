import React, { Component } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { colors } from "Utilities"
import styled from "styled-components"
import BedroomFifteenCarousel from "../../images/gatsby_images/bedroomFifteenCarousel"
import BedroomSixteenCarousel from "../../images/gatsby_images/bedroomSixteenCarousel"
import BedroomSeventeenCarousel from "../../images/gatsby_images/bedroomSeventeenCarousel"
import Shower from "../../images/gatsby_images/shower"
import TV from "../../images/gatsby_images/tv"

export default class BandBCarousel extends Component {
  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    }

    return (
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={4000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        
        <ImageWrapper>
          <Shower
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "100%",
            }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <BedroomFifteenCarousel
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "100%",
            }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <TV
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "100%",
            }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <BedroomSixteenCarousel
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "100%",
            }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <BedroomSeventeenCarousel
            style={{
              display: "block",
              height: "100%",
              margin: "auto",
              width: "100%",
            }}
          />
        </ImageWrapper>
      </Carousel>
    )
  }
}

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0.5rem;
  background-color: ${colors.white};
  border-radius: 5px;
`
