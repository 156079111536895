import React, { Component } from "react"
import Layout from "../../components/layout"
import styled from "styled-components"
import FullEnglish from "../../images/gatsby_images/fullEnglish"
import { colors } from "Utilities"

const textPStyle = {
  textAlign: "center",
  margin: 0,
  marginTop: "1rem",
  padding: 0,
}

class Breakfast extends Component {
  render() {
    return (
      <Layout>
        <BorderOne />
        <TextContainer
          ref={div => (this.myElement = div)}
          className="textContainer"
        >
          <h1>Breakfast</h1>
          <p style={textPStyle}>
            We feel breakfast is an essential part of everyone’s day. So, after
            your relaxing night in our bed and breakfast, head down to the
            breakfast room where we will be glad to serve your morning coffee &#40;or
            juices & tea&#41;, along with a cooked or continental breakfast
            using as much locally sourced produce as we can. We offer
            vegetarian, gluten-free and vegan options as well, just let us know
            your requirements.
          </p>
          <p style={textPStyle}>
            <strong>Breakfast is available from 7.30am to 9.30am daily.</strong>
          </p>
        </TextContainer>
        <ImageWrapper>
          <FullEnglish />
        </ImageWrapper>
      </Layout>
    )
  }
}

export default Breakfast

const ImageWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
  overflow: hidden;
`
const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.white};
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  h4 {
    text-align: center;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
  }
`

const BorderOne = styled.div`
  width: 100%;
  background: #1a2e05;
  border-radius: 5px;
  height: 1em;
  margin-top: 1rem;
`
