import "./index.css"
import Menu from "../../icons/menu.svg"
import Home from "../../icons/home.svg"
import Bed from "../../icons/king_bed.svg"
import Logo from "../../images/ashcott-lawns-logo.png"
import Phone from "../../icons/phone.svg"
import About from "../../icons/about-us.svg"
import Breakfast from "../../icons/breakfast.svg"
import Hotel from "../../icons/hotel.svg"
import LeftArrow from "../../icons/arrow_back.svg"
import Car from "../../icons/car.svg"

import React, { useState, useEffect, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"

const IconNav = () => {
  return (
    <Navbar>
      <NavItem icon={<Menu />}>
        <DropdownMenu></DropdownMenu>
      </NavItem>
    </Navbar>
  )
}

function Navbar(props) {
  return (
    <nav className="navbar">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  )
}

function NavItem(props) {
  const [open, setOpen] = useState(false)

  return (
    <li className="nav-item">
      <a className="menu-button" onClick={() => setOpen(!open)}>
        {props.icon}
      </a>

      {open && props.children}
    </li>
  )
}

function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState("main")
  const [menuHeight, setMenuHeight] = useState(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight
    setMenuHeight(height)
  }

  function DropdownItem(props) {
    return (
      <a
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    )
  }

  return (
    <div className="dropdown" ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <Link to="/" className="link-style">
            <DropdownItem leftIcon={<Home />}>Home</DropdownItem>
          </Link>
          <Link to="/our-story" className="link-style">
            <DropdownItem leftIcon={<About />}>Our Story</DropdownItem>
          </Link>
          <DropdownItem leftIcon={<Hotel />} goToMenu="bed-and-breakfast">
            Bed & Breakfast
          </DropdownItem>
          <Link to="/ashcott-private-hire">
            <DropdownItem leftIcon={<Car />} className="link-style">
              Ashcott Private Hire
            </DropdownItem>
          </Link>
          <Link to="/contact">
            <DropdownItem leftIcon={<Phone />} className="link-style">
              Contact
            </DropdownItem>
          </Link>
          <div className="nav-logo">
            <Link to="/">
              <img className="logo-style" src={Logo} />
            </Link>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "bed-and-breakfast"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon={<LeftArrow />}>
            Return
          </DropdownItem>
          <Link to="/bed-and-breakfast">
            <DropdownItem leftIcon={<Hotel />}>Bed & Breakfast</DropdownItem>
          </Link>
          <Link to="/bed-and-breakfast/room-twelve">
            <DropdownItem leftIcon={<Bed />}>Room Twelve</DropdownItem>
          </Link>
          <Link to="/bed-and-breakfast/room-fifteen">
            <DropdownItem leftIcon={<Bed />}>Room Fifteen</DropdownItem>
          </Link>
          <Link to="/bed-and-breakfast/room-sixteen">
            <DropdownItem leftIcon={<Bed />}>Room Sixteen</DropdownItem>
          </Link>
          <Link to="/bed-and-breakfast/room-seventeen">
            <DropdownItem leftIcon={<Bed />}>Room Seventeen</DropdownItem>
          </Link>
          <Link to="/bed-and-breakfast/breakfast">
            <DropdownItem leftIcon={<Breakfast />}>Breakfast</DropdownItem>
          </Link>
          <div className="nav-logo">
            <Link to="/">
              <img className="logo-style" src={Logo} />
            </Link>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default IconNav
