import React, { Component } from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { colors } from "Utilities"

const textPStyle = {
  textAlign: "center",
  padding: 0,
  marginBottom: "2rem",
}

const textHStyle = {
  textAlign: "center",
  padding: 0,
  marginTop: "3rem",
}

const linkStyle = {
  textDecoration: "underline",
}

class OurStoryPage extends Component {
  render() {
    return (
      <Layout>
        <h1 style={textHStyle}>Our Story</h1>
        <GridWrapper>

          <TextContainer
            ref={div => (this.myElement = div)}
            className="textContainer"
          >
            <h3>Our Goal</h3>
            <p style={textPStyle}>
              Welcome to Ashcott Lawns, a beautiful bed and breakfast in the heart of Somerset. Our charming Grade II listed building blends historical character with modern comfort, providing a cozy countryside retreat. For over 10 years, we’ve welcomed guests seeking a relaxing escape. Whether you’re here for a weekend getaway or a longer stay, our goal is to make you feel right at home.
            </p>
          </TextContainer>
          <TextContainer
            ref={div => (this.myElement = div)}
            className="textContainer"
          >
            <h3>Our Values</h3>
            <p style={textPStyle}>
              At Ashcott Lawns, we pride ourselves on creating a warm and welcoming atmosphere. Our focus is on making each guest feel at home, whether you’re here for a weekend escape or an extended stay. We believe in supporting our local community, which is why our breakfasts feature locally-sourced ingredients, and we collaborate with Ashcott Private Hire to offer seamless travel experiences.
            </p>
          </TextContainer>
          <TextContainer
            ref={div => (this.myElement = div)}
            className="textContainer"
          >
            <h3>The Team</h3>
            <p style={textPStyle}>
              Our team is small but dedicated, led by owner Simon, the team brings a personal touch to everything they do. From crafting our delicious breakfast spreads each morning, to handling the property upkeep, ensuring your stay is as comfortable as possible. We believe that it’s the little details that make a stay truly memorable.
            </p>
          </TextContainer>
          <TextContainer
            ref={div => (this.myElement = div)}
            className="textContainer"
          >
            <h3>The Location</h3>
            <p style={textPStyle}>
              Ashcott Lawns is perfectly situated for those looking to explore the beauty of Somerset. Whether you’re visiting the nearby Glastonbury Tor, the stunning Cheddar Gorge, or dropping by to see your child at Millfield School, our B&B serves as a perfect base. With easy access to local sights and the added convenience of Ashcott Private Hire, your adventures await.
            </p>
          </TextContainer>
          <TextContainer
            ref={div => (this.myElement = div)}
            className="textContainer"
          >
            <h3>Guest Experience</h3>
            <p style={textPStyle}>
              Our guests love the blend of history and modern comfort at Ashcott Lawns. Each morning starts with a hearty breakfast, featuring local produce and a relaxing cup of coffee. The peaceful atmosphere makes it easy to unwind, while our cozy rooms provide the perfect place to rest after a day of exploring. Whether you're here for leisure or business, we’re committed to making your stay exceptional.
            </p>
          </TextContainer>
          <TextContainer
            ref={div => (this.myElement = div)}
            className="textContainer"
          >
            <h3>Commitment to Local</h3>
            <p style={textPStyle}>
              We’re proud of our roots in Somerset and love sharing the best of the region with our guests. From the local produce we serve at breakfast to our partnership with Ashcott Private Hire, supporting the community is a core part of who we are.
            </p>
          </TextContainer>
        </GridWrapper>
      </Layout>
    )
  }
}

export default OurStoryPage

const ImageWrapper = styled.div`
  max-width: 1366px;
  margin: 1rem 0;
`
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  grid-gap: 10px;
  @media (max-width: 469px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`
const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.white};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  h3 {
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 0;
  }
`