// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ashcott-private-hire-js": () => import("./../../../src/pages/ashcott-private-hire.js" /* webpackChunkName: "component---src-pages-ashcott-private-hire-js" */),
  "component---src-pages-bed-and-breakfast-breakfast-js": () => import("./../../../src/pages/bed-and-breakfast/breakfast.js" /* webpackChunkName: "component---src-pages-bed-and-breakfast-breakfast-js" */),
  "component---src-pages-bed-and-breakfast-js": () => import("./../../../src/pages/bed-and-breakfast.js" /* webpackChunkName: "component---src-pages-bed-and-breakfast-js" */),
  "component---src-pages-bed-and-breakfast-room-fifteen-js": () => import("./../../../src/pages/bed-and-breakfast/room-fifteen.js" /* webpackChunkName: "component---src-pages-bed-and-breakfast-room-fifteen-js" */),
  "component---src-pages-bed-and-breakfast-room-seventeen-js": () => import("./../../../src/pages/bed-and-breakfast/room-seventeen.js" /* webpackChunkName: "component---src-pages-bed-and-breakfast-room-seventeen-js" */),
  "component---src-pages-bed-and-breakfast-room-sixteen-js": () => import("./../../../src/pages/bed-and-breakfast/room-sixteen.js" /* webpackChunkName: "component---src-pages-bed-and-breakfast-room-sixteen-js" */),
  "component---src-pages-bed-and-breakfast-room-twelve-js": () => import("./../../../src/pages/bed-and-breakfast/room-twelve.js" /* webpackChunkName: "component---src-pages-bed-and-breakfast-room-twelve-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */)
}

