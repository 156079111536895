import React, { Component } from "react"
import Layout from "../../components/layout"
import styled from "styled-components"
import { colors } from "Utilities"
import StyledBedroomTwelveImage from "../../components/Background-Images/room-12"
import StyledBedroomFifteenImage from "../../components/Background-Images/room-15"
import StyledBedroomImageThree from "../../components/Background-Images/room-16"
import StyledBedroomImageFour from "../../components/Background-Images/room-17"
import LowerBorder from "../../components/book"
import check from "../../images/check_circle_outline-24px.svg"

const headingStyle = {
  textAlign: "center",
  margin: 0,
  padding: 0,
  color: "#ffffff"
}

const textPStyle = {
  textAlign: "center",
  margin: 0,
  marginTop: "1rem",
  padding: 0,
}

class RoomOne extends Component {
  render() {
    return (
      <Layout>
        <BorderOne />
        <TextContainer
          ref={div => (this.myElement = div)}
          className="textContainer"
        >
          <h1>Twin Room - 12</h1>
          <p style={textPStyle}>
            Room 12 is a twin room with an ensuite shower room, recently
            renovated, overlooking the village of Ashcott and the surrounding
            countryside. <br />
            The room has a flat screen television, WiFi, a desk and tea & coffee
            making facilities.
          </p>
          <ListContainer
            ref={div => (this.myElement = div)}
            className="listContainer"
          >
            <h2>Facilities</h2>
            <ListWrapper>
              <li>Shower only</li>
              <li>Free toiletries</li>
              <li>Towels</li>
              <li>Seperate private bathroom</li>
              <li>Accessible height toilet</li>
              <li>Bathroom emergency pull cord</li>
              <li>Grab rails in shower</li>
              <li>Hot water</li>
              <li>Desk</li>
              <li>Fire alarm with light</li>
              <li>Iron</li>
              <li>Ironing facilities</li>
              <li>Refrigerator</li>
              <li>Free high speed internet</li>
              <li>Free WiFi Internet</li>
              <li>Hairdryer</li>
              <li>Non-smoking</li>
              <li>Freeview TV</li>
              <li>Tea and coffee making facilities</li>
              <li>High speed internet</li>
              <li>Wifi Internet</li>
              <li>Heating</li>
              <li>Wardrobe</li>
              <li>Sitting area</li>
              <li>Carpeted floor</li>
              <li>USB plugs / charging station</li>
              <li>High quality bedding</li>
              <li>Additional blankets</li>
              <li>Linen provided</li>
              <li>Blackout curtains/blinds</li>
              <li>Lock on bedroom door</li>
              <li>Electric kettle</li>
              <li>Accessed by staircase</li>
              <li>Keypad access</li>
              <li>Smoke detector</li>
              <li>Dining table</li>
              <li>Bottle of water provided</li>
              <li>Rubbish bin</li>
              <li>High Definition Flat Panel Television</li>
            </ListWrapper>
          </ListContainer>
        </TextContainer>
        <LowerBorder />
        <HeroGalleryContainer
          ref={div => (this.myElement = div)}
          className="heroContainer"
        >
          <BedBreakfastContainer
            ref={div => (this.myElement = div)}
            className="bedroomImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Twin Room - 12</h2>
            </UpperBorder>
            <StyledBedroomTwelveImage />
          </BedBreakfastContainer>
          <TeaRoomContainer
            ref={div => (this.myElement = div)}
            className="tearoomImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Superior Double or Twin Room - 15</h2>
            </UpperBorder>
            <StyledBedroomFifteenImage />
          </TeaRoomContainer>
          <BreezeContainer
            ref={div => (this.myElement = div)}
            className="breezeImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Double Room - 16</h2>
            </UpperBorder>
            <StyledBedroomImageThree />
          </BreezeContainer>
          <GalleryContainer
            ref={div => (this.myElement = div)}
            className="galleryImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Double Room - 17</h2>
            </UpperBorder>
            <StyledBedroomImageFour />
          </GalleryContainer>
        </HeroGalleryContainer>
        <BorderTwo
          ref={div => (this.myElement = div)}
          className="borderThree"
        />
      </Layout>
    )
  }
}

export default RoomOne

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.white};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  h4 {
    text-align: center;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
  }
`
const ListContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 2rem;
  padding-bottom: 0;
  line-height: 1rem;
  h2 {
    padding-bottom: 1rem;
  }
`
const ListWrapper = styled.ul`
  list-style: none;
  padding-left: 0;
  column-count: 3;
  column-gap: 20px;
  li {
    position: relative;
    padding-left: 2rem;
  }
  li:before {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    background-image: url(${check});
    background-size: cover;
    background-position: center;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 900px) {
    column-count: 2;
  }
  @media (max-width: 600px) {
    column-count: 1;
  }
`
const BorderOne = styled.div`
  width: 100%;
  background: #1a2e05;
  border-radius: 5px;
  height: 1em;
  margin: 1rem 0;
`
const BorderTwo = styled.div`
  width: 100%;
  background: #1a2e05;
  border-radius: 5px;
  height: 1em;
  margin-bottom: 1rem;
`
const HeroGalleryContainer = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  grid-gap: 10px;
  margin: 1rem 0;
  @media (max-width: 469px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`
const BedBreakfastContainer = styled.div`
  width: 100%;
`
const TeaRoomContainer = styled.div`
  width: 100%;
`
const BreezeContainer = styled.div`
  width: 100%;
`
const GalleryContainer = styled.div`
  width: 100%;
`
const UpperBorder = styled.div`
  width: 100%;
  background: #1a2e05;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 0px 0px #eab308;
  margin-bottom: 0.2rem;
`
