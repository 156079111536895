export const lightGreen = '#eef3db';
export const darkGreen = '#a3d39c';
export const black = '#2a2728';
export const white = '#fefefe';
export const red = '#fe615a';

export default {
  lightGreen,
  darkGreen,
  black,
  white,
  red
};