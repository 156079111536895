import React from "react"
import styled from "styled-components"
import { colors } from "Utilities"
import facebookLogo from "../images/facebook.png"

const LinkText = {
  textDecoration: "none",
  color: "black",
  backgroundImage: "none",
}

const HeaderText = {
  fontSize: "1.2rem",
  marginBottom: "1rem",
  textAlign: "center",
}

const facebookStyle = {
  width: "100%",
  height: "100%",
  margin: 0,
}

const Footer = () => (
  <FooterWidthWrapper>
    <FooterWrapper>
      <ContactWrapper>
        <FooterText style={HeaderText}>Ashcott Lawns</FooterText>
        <FooterText>4 The Batch</FooterText>
        <FooterText>Ashcott,</FooterText>
        <FooterText>Bridgwater,</FooterText>
        <FooterText>TA7 9PH</FooterText>
        <FooterText>
          <a style={LinkText} href="mailto:info@ashcottlawns.co.uk">
            info@ashcottlawns.co.uk
          </a>
        </FooterText>
        <FooterText>
          <a style={LinkText} href="tel:01458211172">
            +44 &#40;0&#41;1458 211172
          </a>
        </FooterText>
      </ContactWrapper>
      <SocialWrapper>
        <FooterText style={HeaderText}>Find Us</FooterText>
        <FacebookWrapper>
          <a
            style={LinkText}
            href="https://www.facebook.com/lawnstearoom"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={facebookStyle}
              src={facebookLogo}
              alt="Facebook Logo and Link"
            />
          </a>
        </FacebookWrapper>
      </SocialWrapper>
      <OpeningWrapper>
        <FooterText style={HeaderText}>
          The Lawns
          <br />
          Open 7 Days a Week
        </FooterText>
        <FooterText>Check In/Out Times</FooterText>
        <FooterText>Check In - 14:00</FooterText>
        <FooterText>Check Out - 11:00</FooterText>
      </OpeningWrapper>
    </FooterWrapper>
    <Copyright>
      <FooterText>&#169; Copyright 2020 - All Rights Reserved</FooterText>
    </Copyright>
  </FooterWidthWrapper>
)

export default Footer

const FooterWidthWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  background: ${colors.white};
`

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  grid-gap: 10px;
  padding: 1rem;
  width: 100%;
  max-width: 1366px;
`

const OpeningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 210px;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  @media (max-width: 769px) {
    margin-top: 1rem;
    align-items: center;
  }
`

const SocialWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 210px;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  @media (max-width: 769px) {
    margin-top: 1rem;
    align-items: center;
    margin-right: 0;
  }
`

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 210px;
  line-height: 1.5rem;
  @media (max-width: 769px) {
    margin-top: 1rem;
    align-items: center;
  }
`

const FacebookWrapper = styled.div`
  margin: 0 2rem;
  padding: 2px;
  height: 50px;
  width: 50px;
  min-width: 50px;
`

const FooterText = styled.p`
  margin: 0;
`

const Copyright = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  p {
    font-size: 0.8rem;
  }
`
