import React, { Component } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { colors } from "Utilities"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BandBCarousel from "../components/Carousels/bandBCarousel"
import StyledBedroomTwelveImage from "../components/Background-Images/room-12"
import StyledBedroomFifteenImage from "../components/Background-Images/room-15"
import StyledBedroomImageThree from "../components/Background-Images/room-16"
import StyledBedroomImageFour from "../components/Background-Images/room-17"
import LowerBorder from "../components/book"
import check from "../images/check_circle_outline-24px.svg"

gsap.registerPlugin(ScrollTrigger)

const headingStyle = {
  textAlign: "center",
  margin: 0,
  padding: 0,
  color: "#ffffff",

}

const headingStyleOne = {
  textAlign: "center",
  margin: 0,
  padding: 0,
  color: "#000000",
}

const textPStyle = {
  textAlign: "center",
  margin: 0,
  marginTop: "1rem",
  padding: 0,
}

class BedAndBreakfast extends Component {
  componentDidMount() {
    gsap.from(".bAndBContainerOne", {
      scrollTrigger: {
        trigger: ".bAndBContainerOne",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".bAndBCarousel", {
      scrollTrigger: {
        trigger: ".bAndBCarousel",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".listContainer", {
      scrollTrigger: {
        trigger: ".listContainer",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".borderOne", {
      scrollTrigger: {
        trigger: ".borderOne",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".accessContainer", {
      scrollTrigger: {
        trigger: ".accessContainer",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".tableContainer", {
      scrollTrigger: {
        trigger: ".tableContainer",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".borderTwo", {
      scrollTrigger: {
        trigger: ".borderTwo",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".heroContainer", {
      scrollTrigger: {
        trigger: ".heroContainer",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".borderThree", {
      scrollTrigger: {
        trigger: ".borderThree",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Bed & Breakfast" />
        <BorderOne ref={div => (this.myElement = div)} className="borderOne" />
        <TextContainer
          ref={div => (this.myElement = div)}
          className="bAndBContainerOne"
        >
          <h1 style={headingStyleOne}>Ashcott Lawns Bed & Breakfast</h1>
          <p style={textPStyle}>
            Ashcott Lawns B&B is a family run Grade II listed accommodation with
            5 individually designed rooms and free parking. All the rooms
            feature ensuite bathrooms, comfortable beds, spacious desk, flat
            screen TV, tea & coffee making facilities and free wi-fi.
          </p>
          <p style={textPStyle}>
            Breakfast is served in the Breakfast room on site. Then later, guests can
            unwind in the secluded courtyard garden with a drink from our fully licensed bar.
          </p>
        </TextContainer>
        <ImageWrapper
          ref={div => (this.myElement = div)}
          className="bAndBCarousel"
        >
          <BandBCarousel />
          <LowerBorder />
        </ImageWrapper>
        <TextContainer
          ref={div => (this.myElement = div)}
          className="bAndBContainerTwo"
        >
          <ListContainer
            ref={div => (this.myElement = div)}
            className="listContainer"
          >
            <h2>Facilities</h2>
            <ListWrapper>
              <li>Highchairs</li>
              <li>Baggage Storage</li>
              <li>Free WiFi internet</li>
              <li>Weddings & private parties</li>
              <li>Licensed Bar</li>
              <li>Bike lock-up</li>
              <li>Meet & greet service</li>
              <li>Garden</li>
              <li>Breakfast lounge</li>
              <li>Continental breakfast</li>
              <li>Cooked breakfast</li>
              <li>Vegetarian options</li>
              <li>Vegan options</li>
              <li>Gluten-free options</li>
              <li>Wheelchair friendly</li>
              <li>Housekeeping - daily</li>
              <li>Designated smoking area</li>
              <li>Breakfast served in restaurant</li>
              <li>Well lit path to entrance</li>
              <li>Wide entrance to property</li>
              <li>On-site Private Taxi Hire</li>
              <li>Private Car Parking</li>
            </ListWrapper>
          </ListContainer>
          <AccessContainer
            ref={div => (this.myElement = div)}
            className="accessContainer"
          >
            <h3>Accessibility</h3>
            <p>
              All our rooms are on the first floor. However, the stairs have a
              stairlift for mobility difficulty guests.
            </p>
          </AccessContainer>

          <TableContainer
            ref={div => (this.myElement = div)}
            className="tableContainer"
          >
            <h3>Policies</h3>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Check in:</strong>
                  </td>
                  <td>14:00 until 20:00</td>
                </tr>
                <tr>
                  <td>
                    <strong>Check out:</strong>
                  </td>
                  <td>11:00</td>
                </tr>
                <tr>
                  <td>
                    <strong>Child Policy:</strong>
                  </td>
                  <td>Children welcome</td>
                </tr>
                <tr>
                  <td>
                    <strong>Smoking Policy:</strong>
                  </td>
                  <td>No smoking unless using designated smoking area</td>
                </tr>
                <tr>
                  <td>
                    <strong>Pets:</strong>
                  </td>
                  <td>No pets</td>
                </tr>
                <tr>
                  <td>
                    <strong>Accommodation type:</strong>
                  </td>
                  <td>Bed & Breakfast</td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
        </TextContainer>
        <BorderOne ref={div => (this.myElement = div)} className="borderTwo" />
        <HeroGalleryContainer
          ref={div => (this.myElement = div)}
          className="heroContainer"
        >
          <BedBreakfastContainer
            ref={div => (this.myElement = div)}
            className="bedroomImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Twin Room - 12</h2>
            </UpperBorder>
            <StyledBedroomTwelveImage />
          </BedBreakfastContainer>
          <TeaRoomContainer
            ref={div => (this.myElement = div)}
            className="tearoomImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Superior Double or Twin Room - 15</h2>
            </UpperBorder>
            <StyledBedroomFifteenImage />
          </TeaRoomContainer>
          <BreezeContainer
            ref={div => (this.myElement = div)}
            className="breezeImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Double Room - 16</h2>
            </UpperBorder>
            <StyledBedroomImageThree />
          </BreezeContainer>
          <GalleryContainer
            ref={div => (this.myElement = div)}
            className="galleryImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Double Room - 17</h2>
            </UpperBorder>
            <StyledBedroomImageFour />
          </GalleryContainer>
        </HeroGalleryContainer>
        <BorderTwo
          ref={div => (this.myElement = div)}
          className="borderThree"
        />
      </Layout>
    )
  }
}
export default BedAndBreakfast

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 1rem 0;
  background: ${colors.white};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ListContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 2rem;
  padding-bottom: 0;
  line-height: 1rem;
  h2 {
    padding-bottom: 1rem;
  }
`
const ListWrapper = styled.ul`
  list-style: none;
  padding-left: 0;
  column-count: 2;
  column-gap: 20px;
  li {
    position: relative;
    padding-left: 2rem;
  }
  li:before {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    background-image: url(${check});
    background-size: cover;
    background-position: center;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 600px) {
    column-count: 1;
  }
`
const AccessContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 2rem;
  line-height: 1.5rem;
  p {
    margin: 0;
    margin-top: 1rem;
    padding: 0;
  }
`

const TableContainer = styled.div`
  width: 100%;
  padding: 2rem;
  line-height: 1rem;
`
const ImageWrapper = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 0;
`
const BorderOne = styled.div`
  width: 100%;
  background: #1a2e05;
  border-radius: 5px;
  height: 1em;
  margin-top: 1rem;
`
const BorderTwo = styled.div`
  width: 100%;
  background: #1a2e05;
  border-radius: 5px;
  height: 1em;
  margin-bottom: 1rem;
`
const HeroGalleryContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  grid-gap: 10px;
  margin: 1rem 0;
  @media (max-width: 469px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`
const BedBreakfastContainer = styled.div`
  width: 100%;
  height:100%;
`
const TeaRoomContainer = styled.div`
  width: 100%;
  height:100%;
`
const BreezeContainer = styled.div`
  width: 100%;
  height:100%;
`
const GalleryContainer = styled.div`
  width: 100%;
  height:100%;
`
const UpperBorder = styled.div`
  width: 100%;
  background: #1a2e05;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  margin-top: 0rem;
  box-shadow: 0px 5px 0px 0px #eab308;
  margin-bottom: 0.2rem;
`
