import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import StyledAPHImageOne from "../components/Background-Images/APHImageOne"
import { colors } from "Utilities"

const textPStyle = {
  textAlign: "center",
  margin: 0,
  marginTop: "1.5rem",
  padding: 0,
}

const linkStyle = {
  textDecoration: "underline",
}

class AshcottPrivateHirePage extends Component {
  render() {
    return (
      <Layout>
        <GridWrapper>
          <ImageWrapper>
            <StyledAPHImageOne />
          </ImageWrapper>
          <TextContainer
            ref={div => (this.myElement = div)}
            className="textContainer"
          >
            <h1>Ashcott Private Hire</h1>
            <p style={textPStyle}>
              At Ashcott Private Hire Ltd, we pride ourselves on offering reliable, chauffeur-driven transport across Somerset. With a range of services, including airport transfers, corporate travel, and private trips, our aim is to make every journey comfortable and hassle-free. Our fleet includes vehicles like the Mercedes Benz E-Class and Vito, accommodating up to 7 passengers.
            </p>
            <p style={textPStyle}>
              Beyond transportation, we offer personalized tours to iconic local sites like Stonehenge and Cheddar Gorge, giving you the chance to explore Somerset at your own pace. Our wedding transport and school run services provide tailored solutions for special occasions and daily needs.
            </p>
            <p style={textPStyle}>
              Safety is our top priority, with all our drivers being DBS-enhanced checked and fully licensed. With over five years of experience, we’ve built a strong reputation for quality service and customer satisfaction. Whatever your travel needs, we're here to ensure you reach your destination smoothly. For further details, <Link to="https://www.ashcottprivatehire.com/" target="_blank" rel="noopener noreferrer" style={linkStyle}>visit our website.</Link>
            </p>
          </TextContainer>
        </GridWrapper>
      </Layout>
    )
  }
}

export default AshcottPrivateHirePage

const ImageWrapper = styled.div`
  max-width: 1366px;
  margin: 1rem 0;
`
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 10px;
  padding-top: 2rem;
`
const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.white};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  h4 {
    text-align: center;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
  }
`