import React, { Component } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { colors } from "Utilities"
import styled from "styled-components"
import Layout from "../components/layout"
import NewCarousel from "../components/Carousels/homepageCarousel"
import LowerBorder from "../components/book"
import StyledBedroomImage from "../components/Background-Images/bedBreakfastImage"
import StyledAPHImage from "../components/Background-Images/APHImage"
import SEO from "../components/seo"

gsap.registerPlugin(ScrollTrigger)

const headingStyle = {
  textAlign: "center",
  margin: 0,
  padding: 0,
  color: "#ffffff",
}

const textPStyle = {
  textAlign: "center",
  margin: 0,
  marginTop: "1rem",
  padding: 0,
}

class IndexPage extends Component {
  componentDidMount() {
    gsap.from(".mainImage", {
      scrollTrigger: {
        trigger: ".mainImage",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".textContainer", {
      scrollTrigger: {
        trigger: ".textContainer",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 200,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".bedroomImage", {
      scrollTrigger: {
        trigger: ".bedroomImage",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 200,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
  }
  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <ImageWrapper ref={div => (this.myElement = div)} className="mainImage">
          <NewCarousel />
          <LowerBorder />
        </ImageWrapper>

        <TextContainer
          ref={div => (this.myElement = div)}
          className="textContainer"
        >
          <h1>Welcome to Ashcott Lawns</h1>
          <h4>A Charming Bed & Breakfast with Onsite Private Taxi Service</h4>
          <p style={textPStyle}>
            Welcome to Ashcott Lawns, a charming Grade II listed bed & breakfast tucked away in the peaceful village of Ashcott. With the scenic Polden Hills as a backdrop and nestled along an ancient Roman road between Street and Bridgwater, our cosy B&B is the perfect spot for nature lovers, walkers, cyclists, birdwatchers, and photographers.
          </p>
          <p style={textPStyle}>
            We’re also here for those travelling for business or parents visiting their children at nearby Millfield School. Whether you’re here for work, a school visit, or just to unwind, we’ve got you covered.
          </p>
          <p style={textPStyle}>
            To make getting around even easier, we offer an onsite taxi service that can be pre-booked for all your travel needs—whether it’s for local tours, a trip into town, or transport to nearby attractions. Just let us know, and we’ll take care of the rest!
          </p>
          <p style={textPStyle}>
            At Ashcott Lawns, we want to make sure your stay is as relaxing and enjoyable as possible. Each morning, you’ll wake up to a hearty breakfast, ready to kick-start whatever the day holds – be it adventure, work, or quality family time.
          </p>
        </TextContainer>
        <HeroGalleryContainer>
          <BedBreakfastContainer
            ref={div => (this.myElement = div)}
            className="bedroomImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Bed & Breakfast</h2>
            </UpperBorder>
            <StyledBedroomImage />
          </BedBreakfastContainer>
          <APHContainer
            ref={div => (this.myElement = div)}
            className="bedroomImage"
          >
            <UpperBorder>
              <h2 style={headingStyle}>Ashcott Private Hire</h2>
            </UpperBorder>
            <StyledAPHImage />
          </APHContainer>
        </HeroGalleryContainer>
      </Layout>
    )
  }
}

export default IndexPage

const ImageWrapper = styled.div`
  max-width: 1366px;
  margin: 1rem 0;
`
const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.white};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  h4 {
    text-align: center;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
  }
`
const HeroGalleryContainer = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  grid-gap: 10px;
  margin: 1rem 0;
  @media (max-width: 469px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`
const BedBreakfastContainer = styled.div`
  width: 100%;
`
const APHContainer = styled.div`
  width: 100%;
`

const UpperBorder = styled.div`
  width: 100%;
  background: #1a2e05;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 0px 0px #eab308;
  margin-bottom: 0.2rem;
`
