import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { colors } from "Utilities"

const linkStyle = {
  textDecoration: "none",
}

const buttonStyle = {
  margin: 0,
  padding: 0,
  color: "#dee2e6",
  fontSize: "1rem",
}

const BedroomImage = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "bedroom-16-pillow.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.placeholderImage.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section" className={className} fluid={imageData}>
          <SpaceDiv>
            <ButtonWrapper>
              <Link to="/bed-and-breakfast" style={linkStyle}>
                <LinkWrapper>
                  <p style={buttonStyle}>Visit the B&B</p>
                </LinkWrapper>
              </Link>
            </ButtonWrapper>
          </SpaceDiv>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBedroomImage = styled(BedroomImage)`
  width: 100%;
`
const SpaceDiv = styled.div`
  width: 100%;
  height: 25em;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 1rem;
`
const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 10px;
  border-color: #dee2e6;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  width: 200px;
  background: #0070f3;
`

export default StyledBedroomImage
