import React, { Component } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Link } from "gatsby"
import styled from "styled-components"
import { colors, elevation } from "Utilities"
import { Button } from "../assets/Button"
import logo from "../images/ashcott-lawns-logo.png"
import { Card } from "assets"
import IconNav from "../components/Navbar/iconNavbar"

gsap.registerPlugin(ScrollTrigger)

const logoLinkStyles = {
  backgroundImage: "none",
  textDecoration: "none",
}

const logoClass = {
  width: "200px",
  overflow: "hidden",
  margin: "0",
}

const logoMediaClass = {
  width: "150px",
  overflow: "hidden",
  margin: "0",
}

const buttonText = {
  color: "black",
  textDecoration: "none",
  textShadow: "none",
}

class Header extends Component {
  componentDidMount() {
    gsap.from(".header", {
      scrollTrigger: {
        trigger: ".header",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: -200,
      opacity: 0,
      ease: "ease",
      duration: 1,
    })
  }
  render() {
    return (
      <HeaderWrapper>
        <NavWrapper>
          <IconNav />
          <LogoWrapper ref={div => (this.myElement = div)} className="header">
            <Link to="/" style={{ logoLinkStyles }}>
              <LogoPositionWrapper>
                <img style={logoClass} src={logo} alt="Ashott Lawns Logo" />
              </LogoPositionWrapper>
              <LogoMediaWrapper>
                <img
                  style={logoMediaClass}
                  src={logo}
                  alt="Ashott Lawns Logo"
                />
              </LogoMediaWrapper>
            </Link>
          </LogoWrapper>
          <ButtonWrapper>
            <a
              href="https://via.eviivo.com/TheLawnsBTA7"
              target="_blank"
              rel="noopener noreferrer"
              style={buttonText}
            >
              <Button>Book Now</Button>
            </a>
          </ButtonWrapper>
        </NavWrapper>
        <SmallBorder />
        <LowerBorder />
      </HeaderWrapper>
    )
  }
}

export default Header

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: #1a2e05;
  height: 100%;
  ${elevation[1]};
  z-index: 1500;
`

const LowerBorder = styled.div`
  width: 100%;
  background: #27272a;
  padding: 1rem;
  height: 20px;
`
const SmallBorder = styled.div`
  width: 100%;
  background: #ffffff;
  height: 10px;
`

const NavWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 769px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`
const LogoWrapper = styled.div`
  grid-column: 2;
  position: relative;
  justify-self: center;
  width: 400px;
  height: 150px;
  @media (max-width: 769px) {
    width: 200px;
    justify-self: flex-end;
    padding: 0.3rem;
  }
`

const LogoPositionWrapper = styled(Card)`
  width: 100%;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  z-index: 10;
  @media (max-width: 769px) {
    display: none;
  }
`

const LogoMediaWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 135px;
    background-color: #ffffff;
    border-radius: 50%;
  }
`

const ButtonWrapper = styled.div`
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 769px) {
    display: none;
  }
`
