import React from "react"
import { createGlobalStyle } from "styled-components"
import { colors } from "Utilities"
import styled from "styled-components"
import Header from "../components/header"
import Footer from "./footer"

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    
    body {
      margin: 0;
      background: #ffffff;

    }
  }
`


const Layout = ({ children }) => {

  return (
    <div>
      <GlobalStyle />

      <Header />
      <LayoutWrapper>
        <main>{children}</main>
      </LayoutWrapper>
      <Footer />



    </div>
  )
}

export default Layout

const LayoutWrapper = styled.div`
  max-width: 1366px;
  width: 100%;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
`
